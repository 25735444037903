
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { getInstance } from "./plugins/auth";
import DataRefresh from "./mixins/DataRefresh.vue";

@Component
export default class Main extends Mixins(DataRefresh) {
  signOutFab: boolean = false;
  auth: any = getInstance();

  get signedIn() {
    return this.auth.isAuthenticated;
  }

  get maintenanceMode() {
    return this.$store.getters["app/getMaintenanceMode"];
  }

  get maintenanceMessage() {
    return this.$store.getters["app/getMaintenanceMessage"]
  }

  get isAuth0TokenSet() {
    return this.$store.getters["user/getAuth0AccessToken"].length === 0 ? false : true;
  }



  @Watch("signedIn")
  onSignedInChanged(isSignedIn: boolean) {
    this.signOutFab = false;
  }

  @Watch("isAuth0TokenSet")
  onIsAuth0TokenSet(isAuth0TokenSet: boolean) {
    if (isAuth0TokenSet) {
      this.setRefreshInterval(900000); // refresh inverval by 15 minutes = 900000 ms
      this.scheduleAsyncRefresh(this.retrieveAppSettings);
    }
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  signOut(): void {
    this.$store.dispatch("resetAllStores");
    this.auth.logout();
  }

  async retrieveAppSettings() {
    await this.$store.dispatch("app/retrieveAppSettings");
  }

  get copyrightYear() {
    return new Date().getFullYear();
  }
}
